import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ExtraOptionSingle = ({
  dataContainer, variation, onShowTaskInfo,
}) => {
  const [updated, setUpdated] = useState(false);

  let key = `${variation.id} _ ${Math.random()}`;

  dataContainer.registerSelectionHook((variationIds) => {
    if (variationIds.includes(variation.id)) {
      key = `${variation.id} _ ${Math.random()}`;
      setUpdated(!updated);
    }
  });

  return (variation || updated) ? (
    <div key={key} className="flex justify-between items-center mb-16">
      <p className="text-sm flex-grow flex items-center pr-8 sm:text-lg break-all pr-5">
        {variation.key}
        <svg
          onClick={() => {
            onShowTaskInfo(variation);
          }} className="ml-4 relative z-30 cursor-pointer flex-shrink-0" width="22" height="22"
          viewBox="0 0 22 22" fill="none"
        >
          <circle cx="11" cy="11" r="10.5" stroke="#696969" />
          <path
            d="M11.156 7.46V6.004H9.966V7.46H11.156ZM9.966 8.762V16H11.156V8.762H9.966Z"
            fill="#1A1A1A"
          />
        </svg>
      </p>

      <input
        defaultChecked={dataContainer.isVariationSelected(variation.id)}
        onClick={() => { dataContainer.toggleVariationSelection(variation.id); }}
        type="checkbox" className="my-radiobox my-radiobox-others flex-shrink-0 mt-1"
      />
    </div>
  ) : '';
};

ExtraOptionSingle.defaultProps = {
  dataContainer: null,
  variation: null,
  onShowTaskInfo: null,
};

ExtraOptionSingle.propTypes = {
  dataContainer: PropTypes.shape(),
  variation: PropTypes.shape(),
  onShowTaskInfo: PropTypes.func,
};

export default ExtraOptionSingle;
