import axios from 'axios';

export default {
  _mState: 'passive',
  _productId: 1,
  _applicationId: 2,
  _apiKey: '6JmqeovbBtQMgLlmCdDEjObzYc1q28x460GXHNyn',
  _locale: 'en',
  _localeId: '41',
  mLoadedData: null,
  mComponents: null,
  mVariations: null,
  mHierarchical: null,
  mSelectedVariations: [],
  mSelectionHooks: [],
  mApplicationDataChangeHooks: [],
  mInitLoadedScripts: false,
  mLoadedScripts: {},
  mVariationJsAssetsCount: 0,
  mApplicationData: {},
  mSessionId: null,
  mVisitorId: null,
  instance() {
    if (!window.dataContainer) {
      window.dataContainer = this;
    }
    if (!window.onScriptLoaded) {
      window.onScriptLoaded = (variationIframeKey) => {
        const variationId = variationIframeKey.split('_')[0];
        const object = document.getElementById(variationIframeKey).contentWindow.instance();
        const copyOfObject = {};
        const keys = Object.keys(object);
        for (let idx = 0; idx < keys.length; idx += 1) {
          copyOfObject[keys[idx]] = object[keys[idx]];
        }
        window.dataContainer.appendScript(variationId, copyOfObject);
      };
    }
    return this;
  },
  appendScript(variationId, object) {
    this.mLoadedScripts[variationId] = object;
    if (this.mInitLoadedScripts) {
      this.initVariationScripts();
    }
  },
  initVariationScripts() {
    const jsKeys = Object.keys(this.mLoadedScripts);
    if (jsKeys.length === this.mVariationJsAssetsCount) {
      for (let idx = 0; idx < jsKeys.length; idx += 1) {
        this.mLoadedScripts[jsKeys[idx]].onInit(parseInt(jsKeys[idx], 10), this);
      }
    }
  },
  toggleVariationSelectionScript() {
    const selectedIds = this.mSelectedVariations;
    this.getSelectedVariations().forEach((variation) => {
      if (this.mLoadedScripts[variation.id]) {
        if (variation.is_default) return;
        this.mLoadedScripts[variation.id].onSelect(variation.id, this);
        const jsKeys = Object.keys(this.mLoadedScripts);
        if (jsKeys.length > 0) {
          for (let idx = 0; idx < jsKeys.length; idx += 1) {
            this.mLoadedScripts[jsKeys[idx]]
              .onSelectionChanged(variation.id, selectedIds, selectedIds, this);
          }
        }
      }
    });
  },
  registerSelectionHook(callback) {
    this.mSelectionHooks.push(callback);
  },
  registerApplicationDataChangeHook(callback) {
    this.mApplicationDataChangeHooks.push(callback);
  },
  loadApplicationData(callback) {
    axios.get(`https://one-app.onecx.rapidimages.com/api/v1/applications/locale/${this._localeId}`,
      {
        headers: {
          'X-Header-ApiKey': this._apiKey,
          'X-Header-AppId': this._applicationId,
        },
      })
      .then((r) => {
        if (r.data.success) {
          this.mApplicationData = r.data.data;
          if (callback) {
            callback();
          }
        }
      });
  },
  loadData(callback) {
    if (this._mState !== 'passive') {
      return;
    }
    this._mState = 'fetching';
    this.mSessionId = this.uuidv4();
    this.mVisitorId = this.uuidv4();
    axios.get(`https://one-prod.onecx.rapidimages.com/api/v1/products/${this._productId}/data/${this._locale}`,
      {
        headers: {
          'X-Header-ApiKey': this._apiKey,
          'X-Header-AppId': this._applicationId,
        },
      })
      .then((r) => {
        if (!r.data.success) {
          this._mState = 'passive';
          if (callback) {
            callback(null);
          }
        } else {
          this._mState = 'fetched';
          this.mLoadedData = r.data.data;
          this.extractComponentsAndVariations();
          this.loadScriptAssetsForVariations();

          this.loadApplicationData(() => {
            if (callback) {
              callback({
                instance: this,
                plainData: this.mLoadedData,
                variations: this.mVariations,
                components: this.mComponents,
              });
            }
          });
        }
      });
  },
  loadScriptAssetsForVariations() {
    this.mVariations.forEach((v) => {
      if (v.assets.length > 0) {
        v.assets.forEach((vA) => {
          if (vA.asset_type === 'js') {
            this.mVariationJsAssetsCount += 1;
            this.loadInstanceForVariation(v, vA.asset_url);
          }
        });
      }
    });
  },
  loadInstanceForVariation(variation, scriptUrl) {
    const iFrameHtml = `${'<html>'
        + '<head>'
        + "<script src='"}${scriptUrl}'>`
        + '</script>'
        + '</head>'
        + '</html>';

    const iFrameId = `${variation.id}_ifr_${Math.random()}`;

    // eslint-disable-next-line no-unused-vars
    const iframeSource = `<iframe onload='window.onScriptLoaded("${iFrameId}")' id="${iFrameId}" srcdoc="${iFrameHtml}"></iframe>`;
    if (document.getElementById('temp_iframes')) {
      setTimeout(() => {
        document.getElementById('temp_iframes').insertAdjacentHTML('beforeend', iframeSource);
      }, 1500);
    } else {
      throw new DOMException('temp_iframes doesn\'t exist!');
    }
  },
  sectionKey(sectionName, key) {
    if (!this.mApplicationData || !this.mApplicationData.sections) {
      return `${key}`;
    }
    const section = this.mApplicationData.sections.find((s) => s.section_name === sectionName);

    if (!section) {
      return `${key}`;
    }

    if (!Object.keys(section.key_values).includes(key)) {
      return `${key}`;
    }

    return section.key_values[key];
  },
  getAllVariations() {
    return this.mVariations;
  },
  extractComponentsAndVariations() {
    this.mComponents = [];
    this.mVariations = [];
    this.mLoadedData.component_groups.forEach((componentGroup) => {
      componentGroup.components.forEach((component) => {
        const tempComponent = {};
        Object.keys(component).forEach((k) => {
          tempComponent[k] = component[k];
        });
        delete tempComponent.available_variations;
        this.mComponents.push(tempComponent);
        component.available_variations.forEach((v) => {
          this.mVariations.push(v);
          if (v.is_default) {
            this.toggleVariationSelection(v.id, null, true);
          }
        });
      });
    });
    this.mComponents.sort((c1, c2) => c1.layer_order - c2.layer_order);
  },
  loadedData() {
    return this.mLoadedData;
  },
  findParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(component.id,
        excludeDependencyVariationIds);
      if (parentVariations.length > 0 && parentVariations.some((v) => v.assets.some((a) => a.asset_type !== 'png'))) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },
  findNonAssetsParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(component.id,
        excludeDependencyVariationIds, false);
      if (parentVariations.length > 0 && parentVariations.every((v) => v.assets.length === 0 || (!v.is_default && v.assets.some((a) => a.asset_type === 'png')))) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },
  findVariationBy(id) {
    return this.mVariations.find((v) => v.id === id);
  },
  findComponentById(id) {
    return this.mComponents.find((c) => c.id === id);
  },
  findComponentVariations(id) {
    return this.mVariations.filter((v) => v.component_id === id);
  },
  findParentVariationsForComponent(id, excludeDependencyVariationIds, excludeWithoutAssets = true) {
    const componentVariations = this.findComponentVariations(id);
    const variations = [];
    componentVariations.forEach((v) => {
      if (excludeWithoutAssets && v.assets.length === 0) {
        return;
      }
      if (v.dependencies.length === 0) {
        variations.push(v);
      }
      if (excludeDependencyVariationIds
                && v.dependencies.find(
                  (tempV) => excludeDependencyVariationIds.includes(tempV.depends_on_variation_id),
                )) {
        variations.push(v);
      }
    });
    return variations;
  },
  findChildrenForVariation(id) {
    const childrenVariations = [];
    this.mVariations.forEach((v) => {
      if (v.dependencies.find((d) => d.depends_on_variation_id === id)) {
        childrenVariations.push(v);
      }
    });
    return childrenVariations;
  },
  findChildrenComponentsForVariation(variationId) {
    const variations = this.findChildrenForVariation(variationId);
    if (variations.length === 0) {
      return [];
    }

    const componentIds = [...new Set(variations.map((v) => v.component_id))];
    if (!componentIds) {
      return [];
    }
    const components = [];
    componentIds.forEach((cId) => components.push(this.findComponentById(cId)));

    return components;
  },
  isVariationSelected(id) {
    return this.mSelectedVariations.includes(id);
  },
  toggleVariationSelection(id, action, ignoreHooks = false) {
    const oldSelectionState = [];
    if (!ignoreHooks) {
      this.mSelectedVariations.forEach((v) => oldSelectionState.push(v));
    }
    const index = this.mSelectedVariations.indexOf(id);

    const variation = this.findVariationBy(id);
    const component = this.findComponentById(variation.component_id);

    let event;

    if (action !== 'add' && (index !== -1 || action === 'delete')) {
      if (index > -1) {
        if (!ignoreHooks && component.required_choice && this.isVariationSelected(id)) {
          // if the component has only two variations switch to the other variations
          const componentVariations = this.mVariations.filter(
            (v) => v.component_id === component.id && v.id !== id,
          );
          if (componentVariations.length === 1) {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], 'unselect');
            });
            this.mSelectedVariations.splice(index, 1);
            if (typeof this.mLoadedScripts[variation.id] !== 'undefined') {
              this.mLoadedScripts[variation.id].onUnselect(variation, this);
            }
            this.toggleVariationSelection(componentVariations[0].id);
          } else {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], 'select');
            });
            this.logEvent('variation', variation);
          }
          return;
        }
        this.mSelectedVariations.splice(index, 1);
        event = 'unselect';
        if (typeof this.mLoadedScripts[variation.id] !== 'undefined') {
          this.mLoadedScripts[variation.id].onUnselect(variation, this);
        }
      }
      if (!component.multiple && !ignoreHooks) {
        this.clearSameComponentSelection(id);
      }
      this.removeNestedVariationSelection(id);
    } else if (!action || action === 'add') {
      this.mSelectedVariations.push(id);
      if (typeof this.mLoadedScripts[variation.id] !== 'undefined') {
        this.mLoadedScripts[variation.id].onSelect(variation, this);
      }
      event = 'select';
      if (!component.multiple) {
        this.clearSameComponentSelection(id);
        this.selectNestedDefaultVariation(id);
      }
    }
    if (!ignoreHooks) {
      this.mSelectionHooks.forEach((cb) => {
        cb([id], event);
      });
      this.logEvent('variation', variation);
      const newSelectionState = [];
      this.mSelectedVariations.forEach((v) => newSelectionState.push(v));

      const jsKeys = Object.keys(this.mLoadedScripts);
      if (jsKeys.length > 0) {
        for (let idx = 0; idx < jsKeys.length; idx += 1) {
          this.mLoadedScripts[jsKeys[idx]]
            .onSelectionChanged(id, oldSelectionState, newSelectionState, this);
        }
      }
    }
  },
  isVariationDependentOn(variation, onVariation) {
    return variation.dependencies.some(
      (v) => v.depends_on_variation_id === onVariation.id,
    );
  },
  clearSameComponentSelection(id) {
    const selectedVariation = this.findVariationBy(id);
    const deletedVariationIds = [];
    for (let idx = 0; idx < this.mSelectedVariations.length; idx += 1) {
      if (this.mSelectedVariations[idx] !== id) {
        const variation = this.findVariationBy(this.mSelectedVariations[idx]);
        if (selectedVariation.component_id === variation.component_id
        && !this.isVariationDependentOn(selectedVariation, variation)) {
          this.mSelectedVariations.splice(idx - deletedVariationIds.length, 1);
          deletedVariationIds.push(variation.id);
        }
      }
    }
    deletedVariationIds.forEach((v) => this.removeNestedVariationSelection(v, id));
  },
  removeNestedVariationSelection(id, excludeVariation = 0) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach(
        (c) => this.mVariations
          .filter((v) => v.component_id === c.id)
          .forEach((variationToDelete) => {
            if (this.mSelectedVariations.includes(variationToDelete.id)
                && excludeVariation !== variationToDelete.id) {
              this.toggleVariationSelection(variationToDelete.id, 'delete', true);
            }
          }),
      );
    }
  },
  selectNestedDefaultVariation(id) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach(
        (c) => {
          if (c.required_choice) {
            const componentVariations = this.findComponentVariations(c.id);
            componentVariations.forEach((cv) => {
              if (cv.is_default && !this.isVariationSelected(cv.id)) {
                this.toggleVariationSelection(cv.id, 'add', true);
              }
            });
          }
        },
      );
    }
  },
  getSelectedVariations() {
    const selectedVariations = [];
    this.mSelectedVariations.forEach((vId) => selectedVariations.push(this.findVariationBy(vId)));
    return selectedVariations;
  },
  isVariationUnselectable(id) {
    const variation = this.findVariationBy(id);
    if (!variation) {
      return true;
    }

    const component = this.findComponentById(variation.component_id);
    if (!component) {
      return true;
    }

    if (variation.is_default
        && component.required_choice
        && this.mVariations.filter((v) => v.component_id === component.id).length < 2) {
      return false;
    }

    return true;
  },
  generateUniqueHashForSelections() {
    const content = {
      dateTime: new Date().getTime(),
      selections: [],
    };
    this.mSelectedVariations.forEach((v) => content.selections.push(v));
    return btoa(JSON.stringify(content));
  },
  loadSelectionFromHash(hash) {
    try {
      const content = JSON.parse(atob(hash));
      this.mSelectedVariations = [];
      content.selections.forEach((s) => this.mSelectedVariations.push(s));
      this.mSelectionHooks.forEach((cb) => cb(this.mSelectedVariations, 'select'));
    } catch (e) {
      // silient exceptioon
    }
  },
  submitForm(data, callback) {
    axios.post('https://onecx.rapidimages.com/api/v1/contacts',
      data,
      {
        headers: {
          'X-Header-ApiKey': this._apiKey,
          'X-Header-AppId': this._applicationId,
        },
      })
      .then((r) => {
        if (callback) {
          callback(r);
        }
      }).catch((err) => {
        if (callback) {
          callback(err);
        }
      });
  },
  uuidv4() {
    // eslint-disable-next-line no-mixed-operators
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  },
  logEvent(event, variation) {
    axios.post('https://one-ins.onecx.rapidimages.com/api/logs/analytics/analyticTrack',
      {
        e: event,
        e_v: variation.id,
        a: this._applicationId,
        s: this.mSessionId,
        v: this.mVisitorId,
        tags: {
          product_id: 1,
        },
      });
  },
};
