import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCodeTest from 'qrcode.react';
import Button from '../Button';
import CopyClipboardIcon from '../../assets/images/copy-content.svg';

function Configuration(props) {
  const {
    dataContainer,
  } = props;

  const [selectionUrl, setSelectionUrl] = useState('');
  const [copyToClipboard, setCopyToClipboard] = useState('');

  const copyClipboard = () => {
    navigator.clipboard.writeText(selectionUrl);
    setCopyToClipboard('Link has been copied to clipboard');

    setTimeout(() => {
      setCopyToClipboard('');
    }, 1500);
  };

  const onSelected = () => {
    const uniqueHash = dataContainer.generateUniqueHashForSelections();
    setSelectionUrl(`${window.location.origin}/?s=${uniqueHash}`);
  };

  useEffect(() => {
    if (dataContainer) {
      dataContainer.registerSelectionHook(onSelected);
      onSelected();
    }
  }, [dataContainer]);

  return (
    <div className="flex flex-wrap items-center text-gray-darker border-t border-b border-gray py-12 mb-7">
      <div className="w-full mb-7 sm:mb-0 sm:w-1/2 sm:pr-4">
        <h2 className="text-xl font-light">{ dataContainer.sectionKey('Main Site', 'txt_save_link') }</h2>
      </div>
      <div className="w-full sm:w-1/2 flex flex-wrap items-center">
        <picture className="block w-full sm:w-1/2 sm:pr-6">
          <QRCodeTest
            id={selectionUrl}
            value={selectionUrl}
            size={150}
            level="H"
            includeMargin
          />
        </picture>
        <div className="w-full sm:w-1/2">
          <p className="text-tiny mb-4 block">{dataContainer.sectionKey('Main Site', 'txt_link_configuration')}</p>
          <Button href="#" onClick={copyClipboard} text={dataContainer.sectionKey('Main Site', 'btn_copy_link')} iconUrl={CopyClipboardIcon} className="bg-gray-darker hover:bg-primary uppercase text-gray-light hover:text-white text-tiny rounded-3xl py-3 px-3 tems-center justify-center h-10 transition-transform" />
          {copyToClipboard
            ? <span className="absolute block text-tiny mt-3 text-gray-dark">{copyToClipboard}</span> : null}
        </div>
      </div>
    </div>
  );
}

Configuration.propTypes = {
  dataContainer: PropTypes.shape(),
};

Configuration.defaultProps = {
  dataContainer: null,
};

export default Configuration;
