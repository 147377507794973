import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ExternalMenu(props) {
  const { links, isMenuOpen } = props;
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdown(!dropdown);
  };
  const toggleMenu = (e) => {
    e.preventDefault();
    props.onToggleNav(isMenuOpen);
  };
  return (
    <>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleMenu} className={`external-menu-overlay transition-all invisible opacity-0 fixed w-full h-full inset-0 left bg-opacity-60 bg-black z-30 ${isMenuOpen ? 'menu-open' : ''} `} />
      <div className={`external-menu h-full right-0 top-0 fixed transition-all z-40 ${isMenuOpen ? 'menu-open' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={toggleMenu} className="external-menu-close flex w-full justify-end mt-6 pr-6 cursor-pointer">
          <svg className="flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001">
            <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285 3.905 3.905 9.024 5.857 14.143 5.857a19.94 19.94 0 0 0 14.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857a19.94 19.94 0 0 0 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z" />
          </svg>
        </a>
        <ul className="border-b external-border mt-6">
          {
                links && links.map((link) => (
                  <li key={link.name} className="border-t external-border relative">
                    {
                      link.children.length ? (
                        <>
                          <a onClick={toggleDropdown} className="py-2 px-5 w-full flex" href={link.url}>{link.name}</a>
                          <i className={`list-more absolute right-0 top-0 mt-5 pr-6 ${dropdown ? 'list--open' : ''}`}>
                            <span className="flex" />
                            <span className="flex" />
                          </i>
                          <ul className={`list-inner overflow-hidden ${dropdown ? 'list--open' : ''}`}>
                            {
                              link.children.map((innerLink) => (
                                <li key={innerLink.name} className="border-t external-border pl-4">
                                  <a target="_blank" rel="noreferrer" className="py-2 px-5 w-full flex" href={innerLink.url}>{innerLink.name}</a>
                                </li>
                              ))
                            }
                          </ul>
                        </>
                      ) : <a target="_blank" rel="noreferrer" className="py-2 px-5 w-full flex" href={link.url}>{link.name}</a>
                    }
                  </li>
                ))
            }
        </ul>
      </div>
    </>
  );
}

ExternalMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  isMenuOpen: PropTypes.bool,
  onToggleNav: PropTypes.func,
};

ExternalMenu.defaultProps = {
  links: [
    {
      name: 'Business Area',
      url: '#',
      children: [
        {
          name: 'Subsea',
          url: 'https://lidanmarine.com/subsea/',
        },
        {
          name: 'Marine',
          url: 'https://lidanmarine.com/marine/',
        },
        {
          name: 'Naval',
          url: 'https://lidanmarine.com/naval/',
        },
        {
          name: 'Industry',
          url: 'https://lidanmarine.com/#:~:text=Naval-,Industry,-Service%20%26%20aftersales',
        },
        {
          name: 'Service & aftersales',
          url: 'https://lidanmarine.com/service-after-sales/',
        },
      ],
    },
    {
      name: 'Contact',
      url: 'https://lidanmarine.com/contact/',
      children: [],
    },
    {
      name: 'About',
      url: 'https://lidanmarine.com/about/',
      children: [],
    },
  ],
  isMenuOpen: false,
  onToggleNav: false,
};

export default ExternalMenu;
