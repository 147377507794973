import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ExtraOptionMulti = ({
  dataContainer, variation, childrenVariations, onShowTaskInfo,
}) => {
  const [updated, setUpdated] = useState(false);

  let key = `${variation.id} _ ${Math.random()}`;

  dataContainer.registerSelectionHook((variationIds) => {
    if (variationIds.includes(variation.id)
        || childrenVariations.some((v) => variationIds.includes(v.id))) {
      key = `${variation.id} _ ${Math.random()}`;
      setUpdated(!updated);
    }
  });

  return (
    <div key={key} className="flex justify-between items-center mb-16">
      <p className="text-sm flex-grow flex items-center pr-8 sm:text-lg break-all pr-5">
        {variation.key}
        <svg
          onClick={() => {
            onShowTaskInfo(variation);
          }} className="ml-4 relative z-30 flex-shrink-0 cursor-pointer" width="22" height="22"
          viewBox="0 0 22 22" fill="none"
        >
          <circle cx="11" cy="11" r="10.5" stroke="#696969" />
          <path
            d="M11.156 7.46V6.004H9.966V7.46H11.156ZM9.966 8.762V16H11.156V8.762H9.966Z"
            fill="#1A1A1A"
          />
        </svg>
      </p>
      <div className="flex">
        {childrenVariations.map((cv) => (
          <div
            className="radio flex flex-wrap items-center justify-center ml-2"
          >
            <span
              className="w-full text-center text-tiny sm:text-sm mb-2"
            >
              {cv.key}
            </span>
            <input
              type="checkbox"
              id={`${cv.id}_${cv.key}`}
              name={`name_${cv.id}_${cv.key}`}
              defaultChecked={dataContainer.isVariationSelected(cv.id)}
              onClick={() => {
                dataContainer.toggleVariationSelection(cv.id);
              }}
              className="my-radiobox"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
ExtraOptionMulti.defaultProps = {
  dataContainer: null,
  variation: null,
  childrenVariations: null,
  onShowTaskInfo: null,
};

ExtraOptionMulti.propTypes = {
  dataContainer: PropTypes.shape(),
  variation: PropTypes.shape(),
  childrenVariations: PropTypes.shape(),
  onShowTaskInfo: PropTypes.func,
};

export default ExtraOptionMulti;
