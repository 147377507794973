import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from './useForm';

function ContactForm(props) {
  const { dataContainer } = props;
  const [response, setResponse] = useState();

  const getUniqueHashForSelections = () => {
    const uniqueHash = dataContainer.generateUniqueHashForSelections();
    return `${window.location.origin}/?s=${uniqueHash}`;
  };

  const successMailMessage = dataContainer.sectionKey('Main Site', 'success_mail_message');
  const errorMailMessage = dataContainer.sectionKey('Main Site', 'error_mail_message');

  const submitForm = (data) => {
    const request = {
      properties: {
        email: data.emailAddress,
        full_name: data.fullName,
        phone: data.phoneNumber,
        configuration_link: getUniqueHashForSelections(),
      },
    };
    dataContainer.submitForm(request, (res) => {
      // eslint-disable-next-line no-console
      if (res) {
        setResponse({ message: successMailMessage, success: true });
      } else {
        setResponse({ message: errorMailMessage, success: false });
      }
    });
  };

  const {
    handleSubmit, handleChange, data: user, errors,
  } = useForm({
    onSubmit: () => submitForm(user),
  });

  return (
    <div className="text-gray-darker py-12">
      <div className="w-full sm:w-1/2 sm:pr-4">
        <h2 className="text-xl font-light mb-8">
          {dataContainer.sectionKey('Main Site', 'txt_form_title')}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-base" htmlFor="fullName">
              {dataContainer.sectionKey('Main Site', 'input_name')}
              <input
                className="appearance-none border-b rounded-0 w-full py-1 px-0 text-s focus:outline-none focus:shadow-outline"
                type="text" id="fullName" name="fullName"
                onChange={handleChange('fullName')}
                required
              />
            </label>
            {errors.fullName && <p className="text-sm mt-2 text-red-600">{errors.fullName}</p>}
          </div>
          <div className="mb-4">
            <label className="text-base" htmlFor="emailAddress">
              {dataContainer.sectionKey('Main Site', 'input_email')}
              <input
                className="appearance-none border-b rounded-0 w-full py-1 px-0 text-s focus:outline-none focus:shadow-outline"
                type="email" id="emailAddress" name="emailAddress"
                onChange={handleChange('emailAddress')}
                required
              />
            </label>
            {errors.emailAddress && <p className="text-sm mt-2 text-red-600">{errors.emailAddress}</p>}
          </div>
          <div className="mb-4">
            <label className="text-base" htmlFor="phoneNumber">
              {dataContainer.sectionKey('Main Site', 'input_phone')}
              <input
                className="appearance-none border-b rounded-0 w-full py-1 px-0 text-s focus:outline-none focus:shadow-outline"
                type="text" id="phoneNumber" name="phoneNumber"
                onChange={handleChange('phoneNumber')}
                required
              />
            </label>
            {errors.phoneNumber && <p className="text-sm mt-2 text-red-600">{errors.phoneNumber}</p>}
          </div>
          <input
            className="ld-send_btn bg-primary cursor-pointer uppercase text-gray-darker text-tiny rounded-3xl py-3 items-center justify-center h-10 transition-transform pl-8 pr-12 mt-8"
            type="submit" value="Send"
          />
          { response ? <p className={`mt-5 text-tiny ${response.success ? 'text-green-500' : 'text-red-300'}`}>{response.message}</p> : null }
        </form>
      </div>
    </div>
  );
}

ContactForm.defaultProps = {
  dataContainer: null,
};

ContactForm.propTypes = {
  dataContainer: PropTypes.shape(),
};

export default ContactForm;
