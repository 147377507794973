import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ComponentItem from './ComponentItem';

const VariationItem = ({
  dataContainer, component, variation, excludeDependencyVariationIds, padding, onShowTaskInfo,
}) => {
  const [shouldRefresh, setShouldRefresh] = useState(true);

  let childrenComponents = [];
  if (dataContainer.isVariationSelected(variation.id)) {
    childrenComponents = dataContainer.findChildrenComponentsForVariation(variation.id);
    excludeDependencyVariationIds = [variation.id];
    padding = 5;
  }
  const sh = true;

  let key = `${variation.id} _ ${Math.random()}`;

  dataContainer.registerSelectionHook(() => {
    key = `${variation.id} _ ${Math.random()}`;
    setShouldRefresh(!shouldRefresh);
  });

  return shouldRefresh || sh ? (
    <div key={key} className={`variation-${variation.original_key}`}>
      <div className="flex items-center relative mb-5">
        <picture
          className="w-16 h-16 mr-2 sm:mr-8 sm:w-32 sm:h-32 task-image flex flex-none items-center justify-center"
        >
          <source srcSet={variation.assets[0].preview_image_url} />
          <img
            loading="lazy" className="object-cover h-full w-full"
            src={variation.assets[0].preview_image_url}
            alt="logo"
          />
        </picture>
        <p className="relative task-name select-none w-3/6 leading-5 text-tiny mr-5 flex pr-8 items-center">
          {variation.key}
          <svg
            onClick={() => {
              onShowTaskInfo(variation);
            }} className="ml-4 relative z-30 flex-shrink-0 cursor-pointer" width="22" height="22"
            viewBox="0 0 22 22" fill="none"
          >
            <circle cx="11" cy="11" r="10.5" stroke="#696969" />
            <path
              d="M11.156 7.46V6.004H9.966V7.46H11.156ZM9.966 8.762V16H11.156V8.762H9.966Z"
              fill="#1A1A1A"
            />
          </svg>
        </p>
        <label
          htmlFor={`name_${variation.id}_${variation.key}`}
          className={`checkmark-holder ${sh ? 'cursor-pointer' : 'cursor-not-allowed'} block absolute w-full h-full`}
        >
          <input
            aria-label={variation.key} className="absolute opacity-0 h-full w-full z-20 cursor-pointer" type="checkbox"
            id={`checkbox_${variation.id}_${variation.key}`} name={`name_${variation.id}_${variation.key}`}
            defaultChecked={dataContainer.isVariationSelected(variation.id)}
            onChange={() => { dataContainer.toggleVariationSelection(variation.id); }}
            disabled={() => dataContainer.isVariationUnselectable(variation.id)}
          />
          <span
            className="checkmark absolute transform -translate-y-1/2 inset-y-1/2 right-0 border border-gray-dark rounded-full"
          />
        </label>
      </div>
      {childrenComponents && childrenComponents.map((cC) => (
        <ComponentItem
          onShowTaskInfo={onShowTaskInfo}
          excludeDependencyVariationIds={excludeDependencyVariationIds}
          dataContainer={dataContainer} parentComponent={component} component={cC} padding={padding}
        />
      ))}
    </div>
  ) : '';
};

VariationItem.defaultProps = {
  dataContainer: null,
  excludeDependencyVariationIds: [],
  variation: null,
  padding: 0,
  onShowTaskInfo: null,
  component: null,
};

VariationItem.propTypes = {
  dataContainer: PropTypes.shape(),
  excludeDependencyVariationIds: PropTypes.arrayOf(PropTypes.number),
  variation: PropTypes.shape(),
  padding: PropTypes.number,
  onShowTaskInfo: PropTypes.func,
  component: PropTypes.shape(),
};

export default VariationItem;
