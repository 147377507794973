import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function ItemInfo(props) {
  const {
    classNames, variation, dataContainer,
  } = props;
  const [infoText, setInfoText] = useState('');

  useEffect(() => {
    const sectionKey = dataContainer.sectionKey('Product info texts', variation.original_key);
    if (sectionKey) {
      /* eslint-disable-next-line */
      const sectionKeyFormated = sectionKey.replace(/<br\s*[\/]?>/gi, '\n');
      setInfoText(sectionKeyFormated);
    }
  }, []);

  return (
    <>
      <div className={`ld-info text-gray-darker z-10 ${classNames}`}>
        <div className="py-24">
          <h2 className="mb-16 text-xl font-light">{variation.key}</h2>
          <div className="flex flex-wrap items-center">
            {
              variation.assets.length ? (
                <div className="w-full mb-5 sm:w-1/2 sm:pr-4">
                  <div className="aspect-w-1 aspect-h-1">
                    <picture className="block bg-gray-light mr-8 flex items-center justify-center">
                      <source srcSet={variation.assets[0].preview_image_url} />
                      <img className="w-full h-full object-contain" loading="lazy" src={variation.assets[0].preview_image_url} alt="item" />
                    </picture>
                  </div>
                </div>
              ) : null
            }
            <div className={`w-full sm:w-1/2 ${variation.assets.length ? 'sm:pl-16' : ''}`}>
              <p className="mb-10 text-tiny leading-relaxed whitespace-pre-line">{infoText}</p>
              <div className="flex items-center justify-between mb-3 relative sm:w-1/3">
                <p className="relative select-none w-full sm:w-auto text-tiny mr-5 flex pr-8 break-all">Add option</p>
                <label htmlFor="option" className="cursor-pointer relative w-7 h-7 checkmark-holder">
                  <input
                    role="button"
                    className="absolute w-full h-full top-0 left-0 z-10 opacity-0"
                    defaultChecked={dataContainer.isVariationSelected(variation.id)}
                    onClick={() => { dataContainer.toggleVariationSelection(variation.id); }}
                    type="checkbox"
                    name="option"
                  />
                  <span className="checkmark absolute transform -translate-y-1/2 inset-y-1/2 right-0 border border-gray-dark rounded-full" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ItemInfo.defaultProps = {
  variation: null,
  dataContainer: null,
};

ItemInfo.propTypes = {
  classNames: PropTypes.string.isRequired,
  variation: PropTypes.shape(),
  dataContainer: PropTypes.shape(),
};

export default ItemInfo;
