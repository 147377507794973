import React, { createContext, useReducer } from 'react';

const initialState = {
  components: [],
};
export const storeContext = createContext(initialState);

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPONENTS':
      return {
        ...state,
        components: action.payload.components,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line react/prop-types
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (<storeContext.Provider value={[state, dispatch]}>{children}</storeContext.Provider>);
};

export default StateProvider;
