import { Engine, Scene } from '@babylonjs/core';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function
SceneComponent(props) {
  const reactCanvas = useRef(null);
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props;

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, true);
      const scene = new Scene(engine, sceneOptions);
      scene.checkCollisions = true;
      scene.collisionsEnabled = true;
      scene.autoClear = false;
      scene.autoClearDepthAndStencil = false;
      scene.blockMaterialDirtyMechanism = true;
      scene.getAnimationRatio();
      if (scene.isReady()) {
        props.onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce((sceneObservable) => props.onSceneReady(sceneObservable));
      }

      engine.runRenderLoop(() => {
        if (typeof onRender === 'function') {
          onRender(scene);
        }
        scene.render();
      });

      const resize = () => {
        scene.getEngine().resize();
      };

      if (window) {
        window.addEventListener('resize', resize);
      }

      return () => {
        scene.getEngine().dispose();

        if (window) {
          window.removeEventListener('resize', resize);
        }
      };
    }
    return null;
  }, [reactCanvas]);

  return (
    <>
      <canvas id="lidanCanvas" className="babylonCanvas" ref={reactCanvas} {...rest} />
    </>
  );
}

SceneComponent.defaultProps = {
  antialias: false,
  engineOptions: false,
  adaptToDeviceRatio: false,
  sceneOptions: null,
  onRender: () => {},
  onSceneReady: () => {},
};

SceneComponent.propTypes = {
  antialias: PropTypes.bool,
  engineOptions: PropTypes.bool,
  adaptToDeviceRatio: PropTypes.bool,
  sceneOptions: PropTypes.shape({
    clearColor: PropTypes.shape({
      r: PropTypes.number,
      g: PropTypes.number,
      b: PropTypes.number,
      a: PropTypes.number,
    }),
  }),
  onRender: PropTypes.func,
  onSceneReady: PropTypes.func,
};

export default SceneComponent;
