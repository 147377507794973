import React from 'react';

function Spinner() {
  return (
    <div className="square_holder flex relative mx-auto mb-10">
      <div className="square" />
      <div className="square" />
      <div className="square" />
    </div>
  );
}

export default Spinner;
