import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import headerLogo from '../../assets/images/logo.png';
import ExternalMenu from '../ExternalMenu';

function Header(props) {
  const [menu, setMenu] = useState(false);
  const toggleMenu = (e) => {
    if (e === true) {
      props.onMenuOpen(!menu);
      setMenu(!menu);
      return;
    }
    e.preventDefault();
    props.onMenuOpen(!menu);
    setMenu(!menu);
  };
  return (
    <>
      <header className={`py-6 bg-gray-dark transition-all ${menu ? 'transform-left' : ''}`}>
        <div className="container mx-auto px-3">
          <div className="flex items-center justify-between">
            <Link to="/" className="block">
              <picture className="block">
                <source srcSet={headerLogo} />
                <img loading="lazy" src={headerLogo} alt="logo" />
              </picture>
            </Link>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={toggleMenu}>
              <svg width="23" height="17" viewBox="0 0 23 17" fill="none">
                <rect width="22.125" height="2.10714" rx="1.05357" fill="#fff" />
                <rect y="7.375" width="22.125" height="2.10714" rx="1.05357" fill="#fff" />
                <rect y="14.75" width="22.125" height="2.10714" rx="1.05357" fill="#fff" />
              </svg>
            </a>
          </div>
        </div>
      </header>
      <ExternalMenu onToggleNav={toggleMenu} isMenuOpen={menu} />
    </>
  );
}

Header.propTypes = {
  onMenuOpen: PropTypes.func,
};

Header.defaultProps = {
  onMenuOpen: false,
};

export default Header;
