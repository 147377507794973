import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import VariationItem from './VariationItem';

const ComponentItem = ({
  dataContainer, component, parentComponent, excludeDependencyVariationIds, padding, onShowTaskInfo,
}) => {
  const parentVariations = dataContainer
    .findParentVariationsForComponent(component.id, excludeDependencyVariationIds);

  const hasSubChildrent = parentVariations
    .some((v) => dataContainer.findChildrenComponentsForVariation(v.id).length > 0);

  const key = `${component.id} _ ${Math.random()}`;

  const isSameComponent = (parentComponent ? parentComponent.id === component.id : false);

  return (
    <div
      key={key}
      className={`block task-component mb-1 sm:mb-5 pl-${padding} task-c-${component.name}`}
    >
      <h3 className="my-5 font-semibold">
        {!isSameComponent ? component.name : ''}
        {' '}
        {hasSubChildrent ? '-' : ''}
      </h3>
      {
                parentVariations.map((v) => (
                  <VariationItem
                    onShowTaskInfo={onShowTaskInfo}
                    component={component}
                    excludeDependencyVariationIds={excludeDependencyVariationIds}
                    dataContainer={dataContainer} variation={v}
                    padding={padding}
                  />
                ))
            }
    </div>
  );
};

ComponentItem.defaultProps = {
  dataContainer: null,
  excludeDependencyVariationIds: [],
  component: null,
  parentComponent: null,
  padding: 0,
  onShowTaskInfo: null,
};

ComponentItem.propTypes = {
  dataContainer: PropTypes.shape(),
  component: PropTypes.shape(),
  parentComponent: PropTypes.shape(),
  excludeDependencyVariationIds: PropTypes.arrayOf(PropTypes.number),
  padding: PropTypes.number,
  onShowTaskInfo: PropTypes.func,
};

export default ComponentItem;
