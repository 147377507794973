import React from 'react';
import PropTypes from 'prop-types';

function Button(props) {
  const {
    href,
    text,
    className,
    iconUrl,
    onClick,
  } = props;
  return (
    <a href={href} onClick={onClick} className={`inline-flex items-center ${className}`}>
      {text && <span className="mr-4">{text}</span>}
      <img src={iconUrl} alt="icon" />
    </a>
  );
}

Button.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  iconUrl: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  href: '',
  text: '',
  className: '',
  iconUrl: '',
  onClick: '',
};

export default Button;
