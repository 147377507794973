import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import buttonIcon from '../../assets/images/plus.svg';
import buttonIconSummary from '../../assets/images/arrow.svg';
import buttonIconPhone from '../../assets/images/telephone.svg';
import ItemInfo from '../ItemInfo/ItemInfo';
import Summary from '../Summary/Summary';
import Configuration from '../Configuration/Configuration';
import ContactForm from '../ContactForm/ContactForm';
import ComponentItem from './ComponentItem';
import ExtraOptionMulti from './ExtraOptionMulti';
import ExtraOptionSingle from './ExtraOptionSingle';

function AsideForm(props) {
  const { dataContainer, toggleMenu } = props;
  const [showItems, setShowItems] = useState(false);
  const [asideOpen, setAsideOpen] = useState(false);
  const [toggleInfo, setToggleInfo] = useState(false);
  const [toggleSummary, setToggleSummary] = useState(false);
  const [nonAssetComponents, setNonAssetComponents] = useState([]);
  const [currentVariationInfo, setCurrentVariationInfo] = useState(null);

  const overflowRef = React.createRef();

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onSideNav(!asideOpen);
    setAsideOpen(!asideOpen);
    setShowItems(true);
    setToggleInfo(false);
    setToggleSummary(false);
  };

  const handleSummary = (e) => {
    e.preventDefault();
    setToggleSummary(!toggleSummary);
    setShowItems(false);
    setToggleInfo(false);
  };

  const toggleSummarySideMenu = (e) => {
    e.preventDefault();
    props.onSideNav(true);
    setAsideOpen(true);
    setToggleSummary(true);
    setShowItems(false);
    setToggleInfo(false);
  };

  const onShowTaskInfo = (v) => {
    setCurrentVariationInfo(v);
    setAsideOpen(true);
    setToggleInfo(true);
    setShowItems(false);
    setToggleSummary(false);
  };

  useEffect(() => {
    if (dataContainer) {
      setShowItems(true);
      setNonAssetComponents(dataContainer.findNonAssetsParentComponents());
    }
  }, [dataContainer]);

  return (
    <>
      <div
        className={`ld-aside absolute ${toggleMenu ? 'ld-aside-hidden' : ''} right-0 top-0 z-20 h-full transition duration-200 ease-in-out bg-white ${asideOpen ? 'ld-aside--open' : ''} ${toggleInfo || toggleSummary ? 'ld-side-info-open' : ''} `}
      >
        <Button
          onClick={handleChange} text={`${!asideOpen ? dataContainer.sectionKey('Main Site', 'btn_configure') : ''}`} iconUrl={buttonIcon}
          className={`ld-configure bg-primary uppercase text-gray-darker text-tiny z-20 rounded-3xl py-3 fixed -left-5 bottom-5 items-center justify-center h-10 transition-transform${asideOpen ? ' ld-configure--active w-10 px-3' : ' px-4'}`}
        />
        <div className="overflow-y-auto h-full">
          <div ref={overflowRef} className="py-4 px-7 sm:px-10">
            {
              showItems && dataContainer && dataContainer.findParentComponents().map((c) => (
                <>
                  <ComponentItem
                    onShowTaskInfo={(v) => onShowTaskInfo(v)}
                    dataContainer={dataContainer} component={c}
                    padding={0}
                  />
                </>
              ))
            }
            {(nonAssetComponents && !toggleSummary
              ? (
                <div>
                  { !toggleInfo && !toggleSummary ? <h3 className="my-10 font-semibold text-lg">Extra options</h3> : null }
                  <div className="block">
                    {
                      (showItems) && dataContainer
                        && nonAssetComponents.map((c) => (
                          dataContainer.findComponentVariations(c.id).map((v) => {
                            const childrenVariations = dataContainer
                              .findChildrenForVariation(v.id);

                            if (childrenVariations.length > 0) {
                              return (
                                <ExtraOptionMulti
                                  dataContainer={dataContainer} variation={v}
                                  childrenVariations={childrenVariations}
                                  onShowTaskInfo={(vi) => onShowTaskInfo(vi)}
                                />
                              );
                            }
                            return (
                              <ExtraOptionSingle
                                dataContainer={dataContainer}
                                onShowTaskInfo={(vi) => onShowTaskInfo(vi)} variation={v}
                              />
                            );
                          })
                        ))
                    }
                  </div>
                </div>
              )
              : '')}
            <Button
              onClick={handleSummary} text={dataContainer.sectionKey('Main Site', 'btn_summary')} iconUrl={buttonIconSummary}
              className={`bg-gray-darker uppercase text-gray-light text-tiny rounded-3xl py-3 tems-center justify-center h-10 transition-transform px-4${showItems ? '' : ' hidden'}`}
            />
            {currentVariationInfo && toggleInfo ? (<ItemInfo onClose={() => setCurrentVariationInfo(null)} dataContainer={dataContainer} variation={currentVariationInfo} classNames="ld-info--open" />) : ''}
            <div className={`ld-summary${toggleSummary ? ' ld-summary--open' : ''}`}>
              <Summary dataContainer={dataContainer} />
              <Configuration dataContainer={dataContainer} />
              <ContactForm dataContainer={dataContainer} />
            </div>
          </div>
        </div>
      </div>
      <div className={`absolute hidden sm:flex bottom-5 left-5 z-30 ${toggleSummary || toggleInfo ? 'sm:hidden' : ''}`}>
        <Button onClick={toggleSummarySideMenu} text={dataContainer.sectionKey('Main Site', 'btn_summary')} iconUrl={buttonIconSummary} className="bg-gray-darker uppercase text-gray-light text-tiny rounded-3xl py-3 tems-center justify-center h-10 transition-transform px-5 mr-5" />
        <Button href="https://lidanmarine.com/contact/" text={dataContainer.sectionKey('Main Site', 'btn_contact_dealer')} iconUrl={buttonIconPhone} className="bg-primary uppercase text-gray-darker text-tiny rounded-3xl py-3 items-center justify-center px-5" />
      </div>
      <div className={`absolute w-2/4 h-full top-0 left-0 bg-gray-light bg-opacity-70 z-10 transition duration-200 ease-in-out ${toggleSummary ? 'opacity-100 visible' : 'opacity-0 invisible'} `} />
    </>
  );
}

AsideForm.defaultProps = {
  dataContainer: null,
  onSideNav: false,
  toggleMenu: true,
};

AsideForm.propTypes = {
  dataContainer: PropTypes.shape(),
  onSideNav: PropTypes.func,
  toggleMenu: PropTypes.bool,
};

export default AsideForm;
