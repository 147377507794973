import React, { useEffect, useState } from 'react';
import {
  ActionManager,
  Animation,
  ArcRotateCamera,
  AssetsManager,
  Color3,
  CubicEase,
  Database,
  EasingFunction,
  ExecuteCodeAction,
  HardwareScalingOptimization,
  SceneLoader,
  SceneOptimizer,
  SceneOptimizerOptions,
  Vector3,
} from '@babylonjs/core';

import { GLTFFileLoader } from '@babylonjs/loaders';
import PropTypes from 'prop-types';
import SceneComponent from './SceneComponent';
import Spinner from '../Spinner';

SceneLoader.RegisterPlugin(new GLTFFileLoader());

const globalBabylon = {
  scene: {},
  assetManager: {},
  tasks: null,
  loading: true,
  camera: {},
  animationCamera: null,
  hookRegistered: false,
};

function Babylon(props) {
  const [loadingState, setLoadingState] = useState(true);
  const [disableUntiAnimation, setDisableUntiAnimation] = useState(false);
  const [zoom, setZoom] = useState(null);
  const isStringNumeric = (value) => /^-?\d+$/.test(value);

  const {
    dataContainer,
    onTaskSelected,
    isSideNavOpen,
  } = props;

  if (dataContainer && !zoom) {
    setZoom(dataContainer.sectionKey('Main Site', 'zoom_level'));
  }

  const ease = new CubicEase();

  const cameraOptions = (camera, canvas) => {
    camera.attachControl(canvas, true);
    camera.lowerRadiusLimit = 25;
    camera.upperRadiusLimit = 50;
    camera.checkCollisions = true;
    camera.applyGravity = true;
    camera.speed = 1;
    camera.upperBetaLimit = Math.PI / 2.1;
    camera.getProjectionMatrix(true);
    globalBabylon.camera = camera;
  };

  const checkOperator = (number, pref) => {
    if (Math.sign(number) === 1) {
      return number + pref;
    }
    if (Math.sign(number) === -1) {
      return number - pref;
    }
    return number;
  };

  const calcGlbPosition = (positions) => {
    const targetPosition = { ...positions };
    targetPosition._y = checkOperator(targetPosition._y, 8.5);
    targetPosition._z = checkOperator(targetPosition._z, 20.5);
    targetPosition._x = checkOperator(targetPosition._x, 7);
    return targetPosition;
  };

  const animateMeshes = (meshes, mesh) => {
    setDisableUntiAnimation(true);
    const animateMesh = new Animation.CreateAndStartAnimation(
      'animateMesh',
      globalBabylon.camera,
      'position',
      60,
      60,
      globalBabylon.camera.position,
      calcGlbPosition(mesh._absolutePosition),
      0,
      ease,
    );
    animateMesh.onAnimationEnd = () => {
      meshes.forEach((singleMesh) => {
        if (singleMesh.material) {
          const lastEmissiveColor = singleMesh.material.emissiveColor;
          const r = 0.15;
          const g = 0.1;
          const b = 0.1;

          const digitalMethod = () => {
            if (singleMesh.material.emissiveColor.r === r
                && singleMesh.material.emissiveColor.g === g
                && singleMesh.material.emissiveColor.b === b) {
              singleMesh.material.emissiveColor = lastEmissiveColor;
            } else {
              singleMesh.material.emissiveColor = new Color3(r, g, b);
            }
          };
          digitalMethod();
          const digital = setInterval(digitalMethod, 250);

          setTimeout(() => {
            clearInterval(digital);
            singleMesh.material.emissiveColor = lastEmissiveColor;
            setDisableUntiAnimation(false);
          }, 900);

          singleMesh.isPickable = true;
        }
      });
    };
    animateMesh.disposeOnEnd = true;
  };

  const animateMesh = (variation) => {
    if (variation === undefined) {
      return;
    }
    const meshes = [];
    if (!variation.mesh) {
      return;
    }
    if (!variation.mesh.loadedMeshes) {
      return;
    }

    variation.mesh.loadedMeshes.forEach((m, index) => {
      const mesh = m;
      meshes.push(mesh);
      if (index === variation.mesh.loadedMeshes.length - 1) {
        animateMeshes(meshes, mesh);
      }
    });
    globalBabylon.scene.unfreezeActiveMeshes();
  };

  const toggleMeshes = (task) => {
    if (task === undefined) {
      return;
    }

    const data = task.name.split('_');
    if (data.length === 0) {
      return;
    }

    const variationId = parseInt(data[0], 10);
    const variation = globalBabylon.tasks.find((v) => v.id === variationId);

    if (!variation) {
      return;
    }

    if (!variation.mesh || !variation.mesh.loadedMeshes) {
      return;
    }

    const shouldShow = dataContainer.isVariationSelected(variationId);

    variation.mesh.loadedMeshes.forEach((m) => {
      m.isVisible = shouldShow;
      m.doNotSyncBoundingInfo = false;
      m.alwaysSelectAsActiveMesh = false;
    });

    globalBabylon.scene.unfreezeActiveMeshes();
  };

  const createSnackBar = (task) => {
    if (task.mesh) {
      const x = document.getElementById('snackbar');
      x.textContent = task.key;
      x.className = 'snackbar show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  };

  const setMeshActions = (mesh, task) => {
    const variationData = task.name.split('_');
    let variation = null;
    if (variationData.length > 1) {
      variation = globalBabylon.tasks.find((v) => v.id === parseInt(variationData[0], 10));
    }
    mesh.actionManager = new ActionManager(globalBabylon.scene);
    mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPickTrigger,
        }, () => {
          if (variation) {
            animateMesh(variation);
            createSnackBar(variation);
          }
        },
      ),
    );
    mesh.actionManager.registerAction(
      new ExecuteCodeAction({
        trigger: ActionManager.OnPointerOverTrigger,
      }, () => {
        if (variation) {
          createSnackBar(variation);
        }
      }),
    );
  };

  const loadAssetsForTasks = (item) => {
    if (item.assets.length === 0) {
      return;
    }
    item.mesh = globalBabylon.assetManager.addMeshTask(`${item.id}_${item.key}`,
      '', '', item.assets[0].asset_url);
  };

  const loadAssetManager = () => {
    globalBabylon.assetManager = new AssetsManager(globalBabylon.scene);
    globalBabylon.assetManager.useDefaultLoadingScreen = false;

    globalBabylon.assetManager.addMeshTask('ground', '', '', 'hdr/Floor_Grid.glb');

    for (let idx = 0; idx < globalBabylon.tasks.length; idx += 1) {
      if (globalBabylon.tasks[idx].assets.length > 0) {
        loadAssetsForTasks(globalBabylon.tasks[idx]);
      }
    }

    globalBabylon.assetManager.onTaskSuccess = (task) => {
      toggleMeshes(task);
    };

    globalBabylon.assetManager.onFinish = ((tasks) => {
      tasks.forEach((task) => {
        if (typeof task.loadedMeshes === 'undefined') {
          return;
        }
        task.loadedMeshes.forEach((mesh) => {
          setMeshActions(mesh, task);
        });
      });
      globalBabylon.loading = false;

      setLoadingState(false);
      dataContainer.initVariationScripts();

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.s) {
        dataContainer.toggleVariationSelectionScript();
      }

      setTimeout(() => {
        globalBabylon.animationCamera.stop();
      }, 500);
    });

    globalBabylon.assetManager.load();
  };

  const onSceneReady = (scene) => {
    globalBabylon.scene = scene;
    const camera = new ArcRotateCamera('OrbitCamera', 2.0, 5, 30, new Vector3(0, 0, 0), scene);
    const canvas = scene.getEngine().getRenderingCanvas();

    if (camera) {
      const animCamRadius = new Animation('animCam', 'alpha', 45,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CYCLE);
      const keysRadius = [];
      keysRadius.push({
        frame: 0,
        value: 2.0,
      });
      keysRadius.push({
        frame: 3000,
        value: 5.0,
      });
      animCamRadius.setKeys(keysRadius);
      camera.animations.push(animCamRadius);
      globalBabylon.animationCamera = scene.beginAnimation(camera, 0, 3000, false, 1);
    }

    cameraOptions(camera, canvas);
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEIN);

    const options = new SceneOptimizerOptions();
    options.addCustomOptimization(new HardwareScalingOptimization(0, 1));
    new SceneOptimizer(scene, options);

    scene.createDefaultEnvironment({
      createSkybox: true,
      skyboxSize: 150,
      skyboxColor: new Color3(0.8, 0.8, 0.8),
      enableGroundShadow: true,
      groundYBias: 1,
      createGround: true,
      groundSize: 200,
      groundColor: new Color3(0.3, 0.3, 0.3),
      enablePointerMoveEvents: true,
    });
    Database.IDBStorageEnabled = true;
  };

  const selectionChanged = (variationIds) => {
    globalBabylon.tasks.forEach((t) => {
      toggleMeshes(t.mesh);
      if (variationIds.includes(t.id)) {
        animateMesh(t);
      }
    });
  };

  window.addEventListener('orientationchange', (e) => {
    if (e) {
      setTimeout(() => {
        globalBabylon.scene.getEngine().resize();
      }, 10);
    }
  }, false);

  window.addEventListener('resize', () => {
    setTimeout(() => {
      globalBabylon.scene.getEngine().resize();
    }, 10);
  });

  useEffect(() => {
    if (globalBabylon.scene || isSideNavOpen) {
      globalBabylon.scene.getEngine().resize();
    }
    if (dataContainer && !globalBabylon.hookRegistered) {
      dataContainer.registerSelectionHook(selectionChanged);
      globalBabylon.hookRegistered = true;
      globalBabylon.tasks = dataContainer.getAllVariations();
      loadAssetManager();
    }
  }, [onTaskSelected,
    isSideNavOpen, dataContainer]);

  useEffect(() => {
    if (isStringNumeric(zoom)) {
      globalBabylon.camera.lowerRadiusLimit = zoom;
    }
  }, [zoom]);

  return (
    <>
      {loadingState && dataContainer
        ? (
          <>
            <div className="fixed top-0 w-full h-full z-40" />
            <div className="fixed inset-x-0 top-1/2 text-center text-white text-center z-20">
              <Spinner />
              <span className="text-lg">Loading configurator</span>
            </div>
          </>
        ) : null }
      {
        disableUntiAnimation ? (
          <div className="fixed top-0 w-full h-full z-40" />
        ) : null
      }
      <>
        <div id="snackbar" className="snackbar" />
        <SceneComponent
          antialias
          onSceneReady={onSceneReady}
        />
      </>
    </>
  );
}

Babylon.defaultProps = {
  dataContainer: null,
  onTaskSelected: null,
  isSideNavOpen: false,
};

Babylon.propTypes = {
  dataContainer: PropTypes.shape(),
  onTaskSelected: PropTypes.shape(),
  isSideNavOpen: PropTypes.bool,
};

export default Babylon;
