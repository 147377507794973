import React from 'react';
import './App.css';
import { Route, BrowserRouter } from 'react-router-dom';
import HomeTemplate from './templates/template-home';
import StateProvider from './context/StateProvider';

function App() {
  return (
    <StateProvider>
      <BrowserRouter>
        <Route exact path="/home/" component={HomeTemplate} />
        <Route exact path="/" component={HomeTemplate} />
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
