import React, { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import Header from '../components/Header/Header';
import AsideForm from '../components/AsideForm';
import Babylon from '../components/WebGL';
import DataContainer from '../services/DataContainer';

function HomeTemplate() {
  const [componentTriggered, onComponentTriggered] = useState(undefined);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const height = use100vh();
  const halfHeight = height ? height - 86 : '100vh';
  const [dataContainer, setDataContainer] = useState(null);
  const [sideMenuTrigger, setSideMenuTrigger] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  const onSideNav = (side) => {
    setSideNavOpen(side);

    if (!sideMenuTrigger) {
      setSideMenuTrigger(true);
    }
  };
  const onTaskSelect = (task) => {
    onComponentTriggered(task);
  };
  const onToggleMenu = (isOpen) => {
    setToggleMenu(isOpen);
  };

  useEffect(() => {
    DataContainer.loadData(() => {
      setDataContainer(DataContainer.instance());
      setDataReady(true);

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.s) {
        DataContainer.loadSelectionFromHash(params.s);
      }
    });
  });

  return (
    <>
      <Header onMenuOpen={onToggleMenu} />
      <div style={{ height: halfHeight }} className={`transition-all ${sideMenuTrigger ? 'loaded' : ''}  ${toggleMenu ? 'transform-left' : ''}`}>
        <div className="babylonjs overflow-hidden z-10 h-full relative">
          <div className={`babylon-holder block babylon-${sideNavOpen ? '2' : '4'} relative h-full`}>
            <Babylon
              dataContainer={dataContainer}
              onTaskSelected={componentTriggered}
              isSideNavOpen={sideNavOpen}
            />
          </div>
          {
            !dataReady ? '' : (
              <AsideForm
                dataContainer={dataContainer}
                onSelectTask={onTaskSelect}
                onSideNav={onSideNav}
                toggleMenu={toggleMenu}
              />
            )
          }
        </div>
      </div>
    </>
  );
}

export default HomeTemplate;
