import React from 'react';
import PropTypes from 'prop-types';
import Item from '../Item/Item';

function Summary(props) {
  const { dataContainer } = props;

  const getParentVariationName = (vId) => {
    const variation = dataContainer.findVariationBy(vId);
    if (variation && variation.dependencies.length > 0) {
      let name = '';
      variation.dependencies.forEach((d) => {
        const tempV = dataContainer.findVariationBy(d.depends_on_variation_id);
        name += `${tempV.key}`;
      });
      return `${name}: `;
    }
    return '';
  };

  let hasNonAssetVariationsSelected = false;

  if (dataContainer) {
    const variations = dataContainer.getSelectedVariations();
    if (variations) {
      hasNonAssetVariationsSelected = variations.some((v) => v.assets.length === 0);
    }
  }

  return (
    <div className="text-gray-darker py-10">
      <h2 className="mb-24 text-xl font-light">Summary</h2>
      <div className="flex items-center flex-wrap">
        {
            dataContainer && dataContainer.getSelectedVariations().map(
              (variation) => (variation.assets.length > 0 ? (
                <div className={`variation-${variation.original_key} w-full sm:w-1/2 sm:pr-4 mb-9`}>
                  <h3 className="text-xs font-medium mb-5">{dataContainer.findComponentById(variation.component_id).name}</h3>
                  <Item imageSrc={variation.assets[0].preview_image_url} title={variation.key} />
                </div>
              ) : ''),
            )
        }
      </div>
      <div className="items-center flex-wrap">
        {hasNonAssetVariationsSelected ? <h3 className="my-10 font-semibold text-lg">Extra options</h3> : ''}
        <div className="flex">
          {dataContainer && dataContainer.getSelectedVariations().map(
            (variation) => (variation.assets.length === 0 ? (
              <div className="w-1/2 sm:pr-4 mb-9">
                <span className="text-xs font-medium mb-5">
                  {getParentVariationName(variation.id)}
                  {variation.key}
                </span>
              </div>
            ) : ''),
          )}
        </div>
      </div>
    </div>
  );
}

Summary.defaultProps = {
  dataContainer: PropTypes.shape(),
};

Summary.propTypes = {
  dataContainer: null,
};

export default Summary;
