import React from 'react';
import PropTypes from 'prop-types';

function Item(props) {
  const {
    imageSrc,
    title,
  } = props;

  return (
    <div className="flex items-center relative">
      <picture className="w-16 h-16 mr-2 sm:mr-8 sm:w-28 sm:h-28 flex flex-none items-center justify-center">
        <source srcSet={imageSrc} />
        <img loading="lazy" className="object-cover h-full w-full" src={imageSrc} alt="item" />
      </picture>
      {title}
    </div>
  );
}

Item.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Item;
